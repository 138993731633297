import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { FaLastfmSquare, FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { SiFacebook } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { AiFillTwitterCircle } from "react-icons/ai";
import instagram from "../../assets/images/instagram.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "../../../App.css";
import axios from "axios";
const Login = ({ loginModel, loginSuccess, setUser }) => {
  const [login, setLogin] = useState({
    username: "",
    email: "",
    password: "",
    privacyPolicy: false,
    rememberMe: false,
  });

  const [signup, setSignup] = useState(false);
  const { username, email, password, privacyPolicy, rememberMe } = login;

  const handleLogin = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setLogin({
      ...login,
      [name]: value,
    });
  };
  const loginSignup = (e) => {
    e.preventDefault();

    if (signup === false) {
      axios({
        method: "POST",
        url: "/signin",
        data: {
          email,
          password,
        },
      }).then((res) => {
        if (res.status === 200 && res.data !== "Incorrect Email or Password") {
          alert("login successfully");
          setUser(res.data);
          localStorage.setItem("_id", res.data._id);
          loginSuccess();
        } else {
          alert(res.data);
          setSignup(false);
        }
      });
    } else {
      axios({
        method: "POST",
        url: "/signup",
        data: {
          username,
          email,
          password,
        },
      }).then((res) => {
        alert(res.data);
      });
      // alert("signup successfully");
      // loginSuccess();
    }
  };

  const loginGoogle = () => {
    loginSuccess();
    if (signup === false) {
      alert("login successfully");
      loginSuccess();
    } else {
      alert("signup successfully");
      loginSuccess();
    }
  };
  const loginFacebook = () => {
    loginSuccess();
    if (signup === false) {
      alert("login successfully");
      loginSuccess();
    } else {
      alert("signup successfully");
      loginSuccess();
    }
  };
  const loginTwitter = () => {
    loginSuccess();
    if (signup === false) {
      alert("login successfully");
      loginSuccess();
    } else {
      alert("signup successfully");
      loginSuccess();
    }
  };
  const loginInstagram = () => {
    loginSuccess();
    if (signup === false) {
      alert("login successfully");
      loginSuccess();
    } else {
      alert("signup successfully");
      loginSuccess();
    }
  };
  const passportFacebook = () => {
    axios({ method: "GET", url: "/login/fb" }).then((res) => console.log(res));
  };
  const responseGoogle = (response) => {
    // console.log(response.profileObj);

    axios({
      method: "POST",
      url: "/googlelogin",
      data: { response },
    }).then((response) => {
      if (response.status === 200) {
        // const {
        //   email,
        //   image,
        //   username,
        //   _id,
        //   FollowersCount,
        //   FollowingCount,
        //   following,
        //   followers,
        // } = user.data;
        console.log(response.data)
        setUser(response.data);
        // localStorage.setItem("email", email);
        // localStorage.setItem("image", image);
        // localStorage.setItem("username", username);
        localStorage.setItem("_id", response.data._id);
        // localStorage.setItem("FollowersCount", FollowersCount);
        // localStorage.setItem("FollowingCount", FollowingCount);
        alert("login successfully");
        loginSuccess();
      }
    });
  };
  const facebook = (response) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "358755152558012",
        cookie: true,
        xfbml: true,
        version: "v11.0",
      });

      window.FB.AppEvents.logPageView();
      FbSDKLoaded();
    };
    const FbSDKLoaded = () => {
      window.FB.login(
        (res) => {
          console.log(res);
        },
        { scope: "public_profile,email" }
      );
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  const responseFacebook = (response) => {
    console.log(response);

    // axios({
    //   method: "POST",
    //   url: "http://localhost:3000/googlelogin",
    //   data: { response },
    // }).then((user) => {
    //   console.log(user);
    //   if (user.status === 200) {
    //     const { email, image, username, _id } = user.data;
    //     setUser({ email: email, image: image, username: username, _id: _id });
    //     localStorage.setItem("email", email);
    //     localStorage.setItem("image", image);
    //     localStorage.setItem("username", username);
    //     localStorage.setItem("_id", _id);
    //     alert("login successfully");
    //     loginSuccess();
    //   }
    // });
  };

  return (
    <div>
      <Dialog
        open={loginModel}
        onClose={loginSuccess}
        disableBackdropClick={true}
      >
        <div className="text-center p-6">
          <h1 className="text-2xl"> {signup ? "Sign Up" : "Log In"} </h1>{" "}
          <h4 className="py-4 text-sidebar">
            {" "}
            {signup
              ? "Please sign up to enter in a app"
              : "Please login to enter in a app"}{" "}
          </h4>{" "}
          <form onSubmit={loginSignup}>
            <div className="space-y-5">
              {" "}
              {signup ? (
                <section className="flex space-x-3 items-center">
                  <FaUser />
                  <input
                    type="text"
                    value={username}
                    name="username"
                    onChange={handleLogin}
                    placeholder="username"
                    className="rounded-md outline-none border p-2"
                    required
                  />
                </section>
              ) : null}{" "}
              <section className="flex space-x-3 items-center">
                <MdEmail />
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={handleLogin}
                  placeholder="email"
                  className="rounded-md outline-none border p-2"
                  required
                />
              </section>{" "}
              <section className="flex space-x-3 items-center">
                <RiLockPasswordFill />
                <input
                  type="password"
                  value={password}
                  name="password"
                  onChange={handleLogin}
                  placeholder="password"
                  className="rounded-md outline-none border p-2"
                  required
                />
              </section>{" "}
            </div>{" "}
            {signup ? (
              <section className="flex items-center space-x-3 my-5">
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  checked={privacyPolicy}
                  onChange={handleLogin}
                  required
                />
                <h4 className="text-sidebar"> I Agree to Privacy Policy </h4>{" "}
              </section>
            ) : (
              <section className="flex items-center space-x-3 my-5">
                <input
                  type="checkbox"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={handleLogin}
                />{" "}
                <h4 className="text-sidebar"> Remember me </h4>{" "}
              </section>
            )}{" "}
            <button
              className="outline-none border rounded-full border-icons text-icons font-bold w-full p-2"
              type="submit"
            >
              {signup ? "Sign Up" : "Login"}{" "}
            </button>{" "}
          </form>{" "}
          <div className="space-y-3">
            <h4 className="mt-5 text-sidebar">
              {" "}
              {signup ? "" : "Login with"}{" "}
            </h4>{" "}
            {!signup ? (
              <section className="flex justify-center items-center space-x-5">
                <SiFacebook
                  size={25}
                  className="text-facebook cursor-pointer"
                  // onClick={facebook}
                  onClick={passportFacebook}
                />
                {/* <FacebookLogin
                appId="358755152558012"
                autoLoad={false}
                // callback={responseFacebook}
                onClick={facebook}
              /> */}
                ,
                {/* <FcGoogle
                size={28}
                className=" cursor-pointer"
                onClick={loginGoogle}
              />{" "} */}
                <GoogleLogin
                  clientId="483415773246-5a96pd58519pnulmecb8o9vq4old4hpr.apps.googleusercontent.com"
                  buttonText="login"
                  render={(renderProps) => (
                    <button onClick={renderProps.onClick}>
                      <FcGoogle size={28} />
                    </button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  // className=" cursor-pointer googlelogin"
                />
                <AiFillTwitterCircle
                  size={28}
                  className="cursor-pointer text-twitter"
                  onClick={loginTwitter}
                />{" "}
                <img
                  src={instagram}
                  alt="instagram"
                  height="25px"
                  width="25px"
                  className="cursor-pointer"
                  onClick={loginInstagram}
                />{" "}
              </section>
            ) : (
              ""
            )}
          </div>{" "}
          <h4 className="text-sidebar mt-7">
            Already have an account ?{" "}
            <span
              className="text-icons cursor-pointer"
              onClick={() => setSignup(!signup)}
            >
              {signup ? "Login" : "Sign Up"}{" "}
            </span>{" "}
          </h4>{" "}
        </div>{" "}
      </Dialog>{" "}
    </div>
  );
};

export default Login;
