import React from "react";
import { Link } from "react-router-dom";
import TrendingVideos from "../Trending/TrendingVideos";
import "./search.css";
const Search = ({
  openCommentDialog,
  openShare,
  searchArray,
  searchEmailArray,
  setAllTrendingVideos,
  AllTrendingVideos,
  setIndexFunction,
  setSavedFunction,
  user,
}) => {
  return (
    <div>
      <h1 className="text-hardGray text-2xl font-medium">Search</h1>
      <ul className="profiles">
        {searchArray && searchArray.length > 0
          ? searchArray.map((data) => {
              return (
                <>
                  <Link to={`/profile/${data._id}`}>
                    <div className="searchprofiles">
                      <img src={data.image} alt=""></img>
                      <span>{data.username}</span>

                      <li>{data.email}</li>
                    </div>
                  </Link>
                </>
              );
            })
          : "No Person Found"}
      </ul>
      {/* <h4 className="text-hardGray mt-1">
        This video will be published to @user
      </h4> */}

      {/* <div className="mt-5">
        <h1 className="text-hardGray text-2xl font-medium">Trending Now</h1>
        <TrendingVideos
          openCommentDialog={openCommentDialog}
          openShare={openShare}
          setAllTrendingVideos={setAllTrendingVideos}
          AllTrendingVideos={AllTrendingVideos}
          setIndexFunction={setIndexFunction}
          setSavedFunction={setSavedFunction}
          user={user}
        />
      </div> */}
    </div>
  );
};

export default Search;
