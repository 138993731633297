import React from "react";
import Trending from "../Trending/Trending";
import LiveVideos from "./LiveVideos";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import io from "socket.io-client";
import { withRouter } from "react-router";
import Peer from "peerjs-client";

import "./live.css";
import { Avatar } from "@material-ui/core";
let socket;

let arr2 = [];
let found;
class LiveStreaming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [{}],
      peers: {},
      Allcomments: [],
    };
  }
  componentDidMount() {
    const { user, videos } = this.props;
    // console.log(user);

    found = videos.find(
      (element) => element.roomid === this.props.match.params.id
    );
    if (found && found.comments) {
      this.setState({ Allcomments: found.comments });
    }
    if (found) {
      console.log(found);
      axios({
        method: "POST",
        url: "/savelivestreams",
        data: {
          title: found.title,
          roomid: found.roomid,
          streamerimage: found.streamerimage,
          streamername: found.streamername,
          streamerid: found.streamerid,
        },
      })
        .then((response) => {
          console.log("savelivestreams response", response.data);
        })
        .catch((e) => alert(e));
    }
    // socket = io.connect("https://tokk2.com", {
    // socket = io.connect("localhost:5000", {
    socket = io.connect("https://quiet-chamber-51842.herokuapp.com", {
      query: { roomID: this.props.match.params.id },
    });
    // const peer = new Peer(undefined, {
    //   path: "/peerjs",
    //   host: "localhost",
    //   port: "3000",
    // });
    const peer = new Peer(user ? user._id : "", {
      path: "/peerjs",
      host: "/",
      // port: "5000",
    });
    let myaudio = document.createElement("video");

    let grid = document.getElementById("grid");

    myaudio.muted = false;
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: true,
      })
      .then((stream) => {
        addAudioStream(myaudio, stream);

        peer.on("call", (call) => {
          call.answer(stream);

          // const video = document.createElement("video");

          // call.on("stream", (userStream) => {
          //   addAudioStream(video, userStream);
          // });
        });
        socket.on("user-connected", (userID) => connectNewUser(userID, stream));

        let comment = document.getElementById("chat_message");
        let addComment = document.getElementsByClassName("addComment");
        addComment[0].addEventListener("click", (e) => {
          socket.emit("message", comment.value);

          comment.value = "";
        });
        socket.on("createMessage", (message, currentUser) => {
          let commentDiv = document.createElement("div");
          let commentUserName = document.createElement("span");
          let commentUserImage = document.createElement("img");
          commentUserName.innerHTML =
            user && user.username ? user.username : "";
          commentUserImage.src = user && user.image ? user.image : "";
          let commentLi = document.createElement("li");
          commentLi.innerText = message;

          commentDiv.append(commentUserName);
          commentDiv.append(commentUserImage);
          commentDiv.append(commentLi);
          let arr = [];

          arr.push({
            name: commentUserName.innerHTML,
            image: commentUserImage.src,
            comment: commentLi.innerText,
          });
          console.log("comments arr", arr);
          this.setState({ comments: arr });

          this.state.Allcomments.unshift({
            name: commentUserName.innerHTML,
            image: commentUserImage.src,
            comment: commentLi.innerText,
          });
          // this.forceUpdate();
          // ul[0].prepend(found.comments);
          // ul[0].prepend(commentDiv);
        });
      });

    socket.on("user-disconnected", (userID) => {
      // console.log(userID);
      for (let i = 0; i < this.state.peers.length; i++) {
        console.log("in");
        if (userID === this.state.peers[i].userID) {
          console.log("in");
          this.state.peers.splice(i, 1);
        }
        this.forceUpdate();
      }

      // console.log(this.state.peers[0].userID);
      // if (this.state.peers[0].userID) {
      // this.state.peers[0].userID.close();

      //   delete this.state.peers[0].userID;
      // }
    });

    peer.on("open", (id) => {
      socket.emit("join-room", this.props.match.params.id, id);
    });

    const connectNewUser = (userID, stream) => {
      const call = peer.call(userID, stream);
      // const video = document.createElement("video");

      // call.on("stream", (userStream) => {
      //   addAudioStream(video, userStream);
      // });
      // call.on("close", () => {
      //   video.remove();
      // });
      // console.log(this.state.peers);
      // console.log(call);
      arr2.push({ userID: call.peer });
      // console.log(arr2);
      this.setState({ peers: arr2 }, () => console.log(this.state.peers));

      // this.state.peers[userID] = call;
      // this.forceUpdate();
      // console.log(this.state.peers);
      // this.setState({ peers: call });
    };
    const addAudioStream = (audio, stream) => {
      audio.srcObject = stream;
      // audio.src = user && user.image ? user.image : "";
      audio.addEventListener("loadedmetadata", () => {
        audio.play();
      });
      grid.append(audio);
    };
    // console.log(this.state.peers);
  }

  componentDidUpdate() {
    const { videos } = this.props;
    const found = videos.find(
      (element) => element.roomid === this.props.match.params.id
    );
    console.log("this.state.comments", this.state.comments);
    if (found) {
      axios({
        method: "POST",
        url: "/updatelivestreams",
        data: {
          title: found.title,
          roomid: found.roomid,
          people: this.state.peers,
          streamerimage: found.streamerimage,
          streamername: found.streamername,
          comments: this.state.comments,
          streamerid: found.streamerid,
        },
      })
        .then((response) => {
          console.log("updatelivestreams response", response.data);
          // this.setState({ Allcomments: response.data.comments });
        })
        .catch((e) => alert(e));
    }
  }
  render() {
    const { user } = this.props;
    // console.log(this.state.Allcomments);
    return (
      <div className="LiveStreamingmain">
        <div className="main__left">
          <div id="grid"></div>
          {/* {props.match.params.id === } */}
          {/* <h4></h4> */}
          <img
            className="commentImage"
            src={found && found.streamerimage ? found.streamerimage : ""}
            alt=""
          />
          <h1>{found ? found.title : ""}</h1>
        </div>
        <div className="main__right">
          <div className="main__header">
            <h6>Comments</h6>
          </div>
          <div className="main__message_container">
            <div className="messageContainer">
              <img
                className="commentImage"
                src={user && user.image ? user.image : ""}
                alt=""
              />
              <input
                id="chat_message"
                type="text"
                placeholder="Add Comment..."
              />
            </div>

            <button className="addComment">Comment</button>
          </div>
          <div className="main__chat_window">
            <ul className="messages">
              {this.state.Allcomments && this.state.Allcomments.length !== 0
                ? this.state.Allcomments.map((data, i) => {
                    return (
                      <div>
                        <span>{data.name}</span>
                        <Avatar className="avatar" src={data.image}>
                          p
                        </Avatar>
                        {/* <img src={data.image} alt="" /> */}
                        <li>{data.comment}</li>
                      </div>
                    );
                  })
                : ""}
            </ul>
          </div>
          <button>End</button>
        </div>
      </div>
    );
  }
}

export default withRouter(LiveStreaming);
