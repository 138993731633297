import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import profile from "../../../assets/images/avatar.png";
import { makeStyles } from "@material-ui/core/styles";
import ProfileVideos from "./ProfileVideos";
import SavedVideos from "./SavedVideos";
import { withRouter } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
}));

const inviteUser = () => {
  alert("invite user working...");
};
const Profile = (props) => {
  const {
    openCommentDialog,
    openShare,
    user,
    setUser,
    setIndexFunction,
    setSavedFunction,
    saveArrayIndex,
  } = props;

  const [ProfileData, setProfileData] = useState(false);
  const classes = useStyles();
  const [profileVideo, setProfileVideo] = useState(true);
  const [followStatus, setFollowStatus] = useState("");
  useEffect(() => {
    const _id = localStorage.getItem("_id");

    axios({
      method: "POST",
      url: "/getuserprofile/" + props.match.params.id,
    }).then((response) => {
      setProfileData(response.data);

      if (_id === response.data._id) {
      } else {
        axios({
          method: "POST",
          url: "/checkfollow",
          data: {
            user_id: _id,
            otherUser_id: response.data._id,
          },
        }).then((response) => {
          setFollowStatus(response.data);
        });
      }
    });
  }, []);
  let otherId = ProfileData ? ProfileData._id : "";
  let ownId = user ? user._id : "";

  const followPerson = (e) => {
    // user.FollowingCount = parseInt(user.FollowingCount) + 1;
    // ProfileData.FollowersCount = parseInt(ProfileData.FollowersCount) + 1;
    // localStorage.setItem("FollowersCount", user.FollowingCount);
    // localStorage.setItem("FollowingCount", ProfileData.FollowersCount);
    axios({
      method: "POST",
      url: "/followprofile",
      data: {
        ownprofile: user,
        otherprofile: ProfileData,

        // ownProfileFollowingCount: user.FollowingCount,
        // otherprofileFollowerCount: ProfileData.FollowersCount,
      },
    }).then((response) => {
      setFollowStatus("Already followed");
      setUser({
        username: response.data[0].username,
        _id: response.data[0]._id,
        email: response.data[0].email,
        FollowingCount: response.data[0].FollowingCount,
        FollowersCount: response.data[0].FollowersCount,
        image: response.data[0].image,
        AllLikes: response.data[0].AllLikes,
        followers: response.data[0].followers,
        following: response.data[0].following,
        audiouploads: response.data[0].audiouploads,
      });
      setProfileData({
        username: response.data[1].username,
        _id: response.data[1]._id,
        email: response.data[1].email,
        FollowingCount: response.data[1].FollowingCount,
        FollowersCount: response.data[1].FollowersCount,
        image: response.data[1].image,
        AllLikes: response.data[1].AllLikes,
        followers: response.data[1].followers,
        following: response.data[1].following,
        audiouploads: response.data[1].audiouploads,
      });
    });
  };
  const unFollowPerson = (e) => {
    // user.FollowingCount = parseInt(user.FollowingCount) - 1;
    // ProfileData.FollowersCount = parseInt(ProfileData.FollowersCount) - 1;
    // localStorage.setItem("FollowersCount", user.FollowingCount);
    // localStorage.setItem("FollowingCount", ProfileData.FollowersCount);
    axios({
      method: "POST",
      url: "/unfollowprofile",
      data: {
        ownprofile: user,
        otherprofile: ProfileData,

        // ownProfileFollowingCount: user.FollowingCount,
        // otherprofileFollowerCount: ProfileData.FollowersCount,
      },
    }).then((response) => {
      setFollowStatus("Not follow");
      setUser({
        username: response.data[0].username,
        _id: response.data[0]._id,
        email: response.data[0].email,
        FollowingCount: response.data[0].FollowingCount,
        FollowersCount: response.data[0].FollowersCount,
        image: response.data[0].image,
        AllLikes: response.data[0].AllLikes,
        followers: response.data[0].followers,
        following: response.data[0].following,
        audiouploads: response.data[0].audiouploads,
      });
      setProfileData({
        username: response.data[1].username,
        _id: response.data[1]._id,
        email: response.data[1].email,
        FollowingCount: response.data[1].FollowingCount,
        FollowersCount: response.data[1].FollowersCount,
        image: response.data[1].image,
        AllLikes: response.data[1].AllLikes,
        followers: response.data[1].followers,
        following: response.data[1].following,
        audiouploads: response.data[1].audiouploads,
      });
    });
  };

  return (
    <div>
      {/* profile card */}
      <div className="bg-navbar rounded-md p-5 space-y-5">
        <section className="flex space-x-5 items-center">
          <Avatar
            src={ProfileData && ProfileData.image ? ProfileData.image : ""}
            className={classes.large}
          ></Avatar>
          <div>
            <h1 className="text-4xl text-lightGray font-medium">User Name</h1>
            <h4 className="text-lightGray">
              {ProfileData && ProfileData.username ? ProfileData.username : ""}
              {ProfileData && otherId === ownId ? (
                ""
              ) : followStatus === "Not follow" ? (
                <i class="fas fa-user-plus follow" onClick={followPerson}></i>
              ) : followStatus === "Already followed" ? (
                <i
                  class="fas fa-user-minus follow"
                  onClick={unFollowPerson}
                ></i>
              ) : (
                ""
              )}
            </h4>
          </div>
        </section>
        <div className="flex justify-between">
          <section className="flex space-x-5 ">
            <Link to={`/following-list/${props.match.params.id}`}>
              <div className="flex space-x-2">
                <p className="text-hardGray font-bold">
                  {ProfileData ? ProfileData.following.length : 0}
                </p>
                <h4 className="text-sidebar">Following</h4>
              </div>
            </Link>

            <Link to={`/followers-list/${props.match.params.id}`}>
              <div className="flex space-x-2">
                <p className="text-hardGray font-bold">
                  {ProfileData ? ProfileData.followers.length : 0}
                </p>
                <h4 className="text-sidebar">Followers</h4>
              </div>
            </Link>

            <div className="flex space-x-2">
              <p className="text-hardGray font-bold">0</p>
              <h4 className="text-sidebar">Likes</h4>
            </div>
          </section>
          {/* <h1 className="text-hardGray cursor-pointer" onClick={inviteUser}>
            Invite for @user
          </h1> */}
        </div>
      </div>

      {/* videos and saved videos */}
      <div className="flex space-x-10 p-3">
        <h1
          className={`text-lightGray text-xl font-medium w-44 text-center cursor-pointer ${
            profileVideo ? "border-b-2 border-hardGray" : null
          } `}
          onClick={() => setProfileVideo(true)}
        >
          Videos
        </h1>
        <h1
          className={`text-lightGray text-xl font-medium w-44 text-center cursor-pointer ${
            profileVideo ? null : "border-b-2 border-hardGray"
          } `}
          onClick={() => setProfileVideo(false)}
        >
          Saved Videos
        </h1>
      </div>

      <div>
        {profileVideo ? (
          <ProfileVideos
            openCommentDialog={openCommentDialog}
            openShare={openShare}
            user={ProfileData}
            audiouploads={ProfileData.audiouploads}
            ProfileData={ProfileData}
            setIndexFunction={setIndexFunction}
            setSavedFunction={setSavedFunction}
            setUser={setUser}
          />
        ) : (
          <SavedVideos
            openShare={openShare}
            setIndexFunction={setIndexFunction}
            setSavedFunction={setSavedFunction}
            openCommentDialog={openCommentDialog}
            saveArrayIndex={saveArrayIndex}
            user={ProfileData}
            setUser={setUser}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(Profile);
