import React, { Component } from "react";

class RecordApp extends Component {
  stream = null;
  mediaRecorder = null;
  audioBlob = [];
  base64new = [];
  audioUrl = null;
  state = {
    mediaRecorderState: "inactive",
  };
  createStream() {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }
  async createRecorder() {
    if (!this.stream) this.stream = await this.createStream();
    if (!this.mediaRecorder) {
      this.mediaRecorder = new MediaRecorder(this.stream);
      let audioChunks = [];
      this.mediaRecorder.addEventListener("start", (event) => {
        this.audioUrl = "";

        audioChunks = [];
        this.setState({ mediaRecorderState: "recording" });
      });
      this.mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);

        if (this.state.mediaRecorderState !== "recording")
          this.setState({ mediaRecorderState: "recording" });
      };
      this.mediaRecorder.onstop = async (event) => {
        this.props.setBuffer(audioChunks);
        this.audioBlob = new Blob(audioChunks);

        this.props.setSaveAudioBlob(this.audioBlob);
        this.audioUrl = URL.createObjectURL(this.audioBlob);

        this.setState({ mediaRecorderState: "inactive" });
        // this.uploadAudio(this.audioBlob, this.audioUrl);
      };
      this.mediaRecorder.onpause = (event) => {
        this.setState({ mediaRecorderState: "paused" });
      };
      this.mediaRecorder.onresume = (event) => {
        this.setState({ mediaRecorderState: "recording" });
      };
    }
    return this.mediaRecorder;
  }

  get isRecording() {
    return this.mediaRecorder && this.mediaRecorder.state === "recording";
  }
  async toggleRecord() {
    await this.createRecorder();
    if (this.mediaRecorder.state === "inactive") {
      this.mediaRecorder.start(1000);
    } else if (this.mediaRecorder.state === "paused") {
      this.mediaRecorder.resume();
    } else if (this.mediaRecorder.state === "recording") {
      this.mediaRecorder.pause();
    }
  }
  async stop() {
    if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") return;
    this.mediaRecorder.stop();
    this.props.setDisabledbtn(!this.props.disabledbtn);
  }

  render() {
    return (
      <div>
        <button onClick={(e) => this.toggleRecord()} className="p-3">
          {this.state.mediaRecorderState === "inactive"
            ? "Record"
            : this.state.mediaRecorderState === "paused"
            ? "Resume"
            : "Pause"}
        </button>
        <button onClick={(e) => this.stop()}>Stop</button>

        <audio controls controlsList="delete, mute" src={this.audioUrl} />
      </div>
    );
  }
}

export default RecordApp;
