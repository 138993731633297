import React, { useEffect, useState } from "react";
import AllCastVideos from "./CastAllAudios";
import axios from "axios";
const CastVideos = (props) => {
  const {
    openCommentDialog,
    openShare,
    moreCast,
    podcastAudios,
    user,
    setUser,
    setIndexFunction,
    setSavedFunction,
    setPodcastAudios,
    openCastCommentDialog,
    openCastShare,
  } = props;
  // podcastAudios.slice(0, moreCast).map

  return (
    <div className="flex flex-wrap">
      {podcastAudios &&
      podcastAudios.length > 0 &&
      podcastAudios[0].podcastuploads
        ? podcastAudios.map((audio, i) => {
            return (
              <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1">
                <AllCastVideos
                  key={i}
                  index={i}
                  title={audio.podcastuploads.title}
                  name={audio.podcastuploads.username}
                  audioUrl={audio.podcastuploads.audio.filename}
                  tags={audio.podcastuploads.tags}
                  comments={audio.podcastuploads.comments}
                  user={user}
                  likeStatus={
                    user &&
                    user.AllLikes.find((data) => {
                      return (
                        data.filename === audio.podcastuploads.audio.filename
                      );
                    })
                  }
                  category={audio.podcastuploads.category}
                  image={audio.podcastuploads.image}
                  likes={audio.podcastuploads.likes}
                  shares={audio.podcastuploads.shares}
                  openCommentDialog={openCommentDialog}
                  videoThumbnail={audio.videoThumbnail}
                  openCastShare={openCastShare}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                  setPodcastAudios={setPodcastAudios}
                  openCastCommentDialog={openCastCommentDialog}
                />
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default CastVideos;
