import React, { useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import axios from "axios";
const Keywords = (props) => {
  // const keywords = ["action", "sad", "comedy", "traveling"];
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios({
      method: "GET",
      url: "/getcategories",
    }).then((response) => {
      setAllCategories(response.data[0].category.sort());
    });
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const { setAllTrendingVideos } = props;
  const rightClick = () => {};
  const leftClick = () => {};
  const getByCategory = (e) => {
    axios({
      method: "GET",
      url: "/getaudiobycategory/" + e.target.innerText.toLowerCase(),
    }).then((response) => {
      setAllTrendingVideos(response.data);
    });
  };
  //keywords.slice(start, end).map
  return (
    <div className="flex flex-wrap space-y-1 space-x-3 py-3 overflow-hidden items-center">
      {start !== 0 ? (
        <GrFormPrevious className="cursor-pointer" onClick={leftClick} />
      ) : null}

      {/* {keywords.map((keyword) => (
        <div key={keyword}>
          <button
            onClick={getByCategory}
            className="rounded-full border bg-navbar py-1 px-2"
          >
            {keyword}
          </button>
        </div>
      ))} */}
      {allCategories &&
        allCategories.length > 0 &&
        allCategories.map((keyword) => (
          <div key={keyword}>
            <button
              onClick={getByCategory}
              className="rounded-full border bg-navbar py-1 px-2"
            >
              {keyword}
            </button>
          </div>
        ))}
      {/* 
      {keywords.length > end ? (
        <GrFormNext className="cursor-pointer" onClick={rightClick} />
      ) : null} */}
    </div>
  );
};

export default Keywords;
