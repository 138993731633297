import { Avatar, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";

import AudioPlayer from "react-h5-audio-player";

import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import "./comments.css";
const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const Comment = (props) => {
  const [CommentVideos, setCommentVideos] = useState([]);
  const [newComment, setNewComment] = useState([]);
  const [CommentArray, setCommentArray] = useState([]);
  let { openComment, closeCommentDialog, user, IndexArray } = props;

  useEffect(() => {
    axios({
      method: "POST",
      url: "/getcomments",
      data: { IndexArray },
    }).then((response) => {
      setCommentVideos(response.data);
      setCommentArray(response.data);
    });
  }, [IndexArray]);
  const classes = useStyles();

  const sendComment = (e) => {
    let inputMessage = document.getElementById("chat_message");

    axios({
      method: "POST",
      url: "/addcommentaudios",
      data: {
        _id: CommentVideos[0]._id,
        username: CommentVideos[0].username,
        image: CommentVideos[0].image,
        comment: newComment,
        audioUrl: IndexArray,
      },
    }).then((response) => {
      setCommentArray(response.data);
    });
    inputMessage.value = "";
  };
  return (
    <div>
      <Dialog maxWidth="xl" open={openComment} onClose={closeCommentDialog}>
        <div className="bg-mainBg commentDialog">
          <h1 className="text-hardGray text-3xl p-3">Audio</h1>
          <div className="flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0 p-3">
            <div className="w-full">
              <div className="bg-navbar rounded-md p-2 leftPartComment">
                {CommentVideos &&
                CommentVideos.length > 0 &&
                CommentVideos[0].audiouploads
                  ? CommentVideos[0].audiouploads.map((data) => {
                      const splitString = data.tags[0].split(",");

                      return (
                        <>
                          <div
                            style={{
                              position: "relative",
                              paddingTop: "56.25%",
                            }}
                          >
                            <AudioPlayer
                              src={`/getsingleaudio/${IndexArray}`}
                              onPlay={(e) => console.log("onPlay")}
                              width="100%"
                              height="100%"
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                              }}
                            />
                          </div>

                          <div className="space-y-7 mt-3">
                            <div className="flex space-x-7">
                              <Avatar
                                src={CommentVideos[0].image}
                                className={classes.large}
                              ></Avatar>
                              <div className="space-y-1">
                                <h4 className="text-lightGray font-medium text-xl">
                                  {data.title}
                                </h4>
                                <h5 className="text-lightGray">
                                  {data.message}
                                </h5>
                              </div>
                            </div>
                            <div className="flex space-x-10">
                              <section className="flex flex-col items-center text-heart">
                                <AiFillHeart
                                  style={{ height: "40px", width: "40px" }}
                                  className="cursor-pointer"
                                  size={23}
                                />
                                <h4 className="text-xs font-bold">
                                  {data.likes}
                                </h4>
                              </section>
                              <section className="flex flex-col items-center text-heart">
                                <FaCommentDots
                                  style={{ height: "40px", width: "40px" }}
                                  className="cursor-pointer"
                                  size={23}
                                />
                                <h4 className="text-xs font-bold">
                                  {
                                    CommentVideos[0].audiouploads[0].comments
                                      .length
                                  }
                                </h4>
                              </section>
                              <section className="flex flex-col items-center text-heart">
                                <IoIosShareAlt
                                  style={{ height: "40px", width: "40px" }}
                                  className="cursor-pointer"
                                  size={23}
                                />
                                <h4 className="text-xs font-bold">
                                  {CommentVideos[0].audiouploads[0].shares}
                                </h4>
                              </section>
                            </div>
                            <ul className="input-tag__tags">
                              {splitString.map((data2) => (
                                <li className="p-1">{`#${data2}`}</li>
                              ))}
                            </ul>
                          </div>
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="bg-navbar p-3 commentScreen">
              <h4 className="border-b text-center pb-3">Comments</h4>
              <div className="main__message_container">
                <div className="messageContainer">
                  {/* <img
                    className="commentImage"
                    src={user && user.image ? user.image : ""}
                    alt=""
                  /> */}
                  <Avatar
                    className="addCommentAvatar"
                    src={user && user.image ? user.image : ""}
                  ></Avatar>
                  <input
                    id="chat_message"
                    type="text"
                    placeholder="Add Comment..."
                    onChange={(e) => {
                      setNewComment(e.target.value);
                    }}
                  />
                </div>
                <div className="addCommentdiv">
                  <button className="addCommentbtn" onClick={sendComment}>
                    Comment
                  </button>
                </div>
              </div>
              <div className="Audio__chat_window">
                <div className="space-y-3">
                  {CommentArray &&
                  CommentArray.length > 0 &&
                  CommentArray[0].audiouploads &&
                  CommentArray[0].audiouploads.length > 0
                    ? CommentArray[0].audiouploads[0].comments.map(
                        (data, i) => {
                          return (
                            <div className="flex space-x-5 items-center">
                              <Avatar src={data.image}></Avatar>
                              <section>
                                <h1 className="text-sm text-lightGray">
                                  {data.username}
                                </h1>
                                <h4 className="text-xs text-lightGray">
                                  {data.comment}
                                </h4>
                              </section>
                            </div>
                          );
                        }
                      )
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Comment;
