import React from "react";
import "./App.css";

import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/UI Components/Home/Home";
import Profile from "./components/UI Components/Profile/Profile";
import FollowingList from "./components/UI Components/FollowingList/FollowingList";
import Live from "./components/UI Components/Live/Live";
import VoiceRecording from "./components/UI Components/VoiceRecording/VoiceRecording";
import VoiceRecordingPodcast from "./components/UI Components/VoiceRecording/podcastVoiceRecording";
import Trending from "./components/UI Components/Trending/Trending";
import Cast from "./components/UI Components/Cast/Cast";
import Setting from "./components/UI Components/Setting/Setting";
import Comment from "./components/UI Components/Comments/Comment";
import PodcastComment from "./components/UI Components/Comments/commentsPodcast";
import { useState, useEffect } from "react";
import Share from "./components/UI Components/Comments/Share";
import SharePodcast from "./components/UI Components/Comments/sharePodcast";
import Upload from "./components/UI Components/Upload/Upload";
import UploadPodcast from "./components/UI Components/Upload/podcastupload";
import Search from "./components/UI Components/Search/Search";
import Login from "./components/UI Components/Login/Login";
import LiveStreaming from "./components/UI Components/Live/LiveStreaming";
import AdminPanel from "./components/UI Components/Admin/admin";
import axios from "axios";
import Followerslist from "./components/UI Components/FollowersList/followerslist";
import AddCategories from "./components/UI Components/Admin/addCategories";
import EditCategories from "./components/UI Components/Admin/EditCategory";

function App() {
  const [videos, setVideos] = useState([{}]);
  const [AllTrendingVideos, setAllTrendingVideos] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [openCastComment, setOpenCastComment] = useState(false);
  const [open, setOpen] = useState(false);
  const [CastOpen, setCastOpen] = useState(false);
  const [user, setUser] = useState();
  const [IndexArray, setIndex] = useState();
  const [saveArrayIndex, setsaveArrayIndex] = useState();
  const [liveStreams, setLiveStreams] = useState();
  const [searchArray, setSearchArray] = useState([]);
  const [searchEmailArray, setEmailSearchArray] = useState([]);
  const [podcastAudios, setPodcastAudios] = useState([]);
  const openCommentDialog = () => {
    setOpenComment(true);
  };
  const closeCommentDialog = () => {
    setOpenComment(false);
  };

  const openShare = () => {
    setOpen(true);
  };
  const closeShare = () => {
    setOpen(false);
  };

  const openCastCommentDialog = () => {
    setOpenCastComment(true);
  };
  const closeCastCommentDialog = () => {
    setOpenCastComment(false);
  };
  const openCastShare = () => {
    setCastOpen(true);
  };
  const closeCastShare = () => {
    setCastOpen(false);
  };
  // login
  const [loginModel, setLoginModel] = useState(true);

  const loginSuccess = () => {
    setLoginModel(false);
    // const username = localStorage.getItem("username");

    // const email = localStorage.getItem("email");

    // const image = localStorage.getItem("image");

    // const _id = localStorage.getItem("_id");
    // const FollowingCount = localStorage.getItem("FollowingCount");

    // const FollowersCount = localStorage.getItem("FollowersCount");

    // setUser({
    //   email: email,
    //   image: image,
    //   username: username,
    //   _id: _id,
    //   FollowersCount: FollowersCount,
    //   FollowingCount: FollowingCount,
    // });
  };

  const logoutSuccess = () => {
    // user.logOut();
    setLoginModel(true);
  };
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    axios({ method: "GET", url: "/getallstreams" })
      .then((response) => {
        setVideos(response.data);
      })
      .catch((e) => alert(e));
  }, [setVideos]);
  useEffect(() => {
    axios({
      method: "POST",
      url: "/getallaudios",
    })
      .then((response) => {
        setAllTrendingVideos(response.data);
      })
      .catch((e) => alert(e));
  }, [setAllTrendingVideos]);
  useEffect(() => {
    axios({ method: "POST", url: "/getallpodcasts" }).then((response) => {
      setPodcastAudios(response.data);
    });
  }, [setPodcastAudios]);
  const setIndexFunction = (index) => {
    setIndex(index);
  };
  const setSavedFunction = (index) => {
    setsaveArrayIndex(index);
  };

  return (
    <Router>
      <div className="relative">
        {menu ? (
          <div
            className="absolute top-0 right-0 z-10 sm:w-2/4 md:w-1/4 w-full lg:hidden"
            onClick={() => setMenu(false)}
          >
            <Sidebar user={user} />
          </div>
        ) : null}
        <div className="wrapper h-screen">
          <Login
            loginModel={loginModel}
            loginSuccess={loginSuccess}
            setUser={setUser}
          />
          <Share
            closeShare={closeShare}
            open={open}
            IndexArray={IndexArray}
            user={user}
          />
          <SharePodcast
            closeCastShare={closeCastShare}
            CastOpen={CastOpen}
            IndexArray={IndexArray}
            user={user}
          />
          <Comment
            openComment={openComment}
            closeCommentDialog={closeCommentDialog}
            IndexArray={IndexArray}
            user={user}
          />
          <PodcastComment
            openCastComment={openCastComment}
            closeCastCommentDialog={closeCastCommentDialog}
            IndexArray={IndexArray}
            user={user}
          />
          <div className="nav">
            <Navbar
              logoutSuccess={logoutSuccess}
              menu={menu}
              openMenu={openMenu}
              user={user}
              setSearchArray={setSearchArray}
              setEmailSearchArray={setEmailSearchArray}
            />
          </div>
          <div className="sidebar hidden lg:block">
            <Sidebar user={user} />
          </div>
          <div className="main p-5">
            <Switch>
              <Route exact path="/">
                <Home
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  setAllTrendingVideos={setAllTrendingVideos}
                  AllTrendingVideos={AllTrendingVideos}
                  videos={videos}
                  user={user}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                  podcastAudios={podcastAudios}
                  setPodcastAudios={setPodcastAudios}
                  openCastCommentDialog={openCastCommentDialog}
                  closeCastCommentDialog={closeCastCommentDialog}
                  openCastShare={openCastShare}
                />
              </Route>
              <Route exact path={`/profile/:id`}>
                <Profile
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  user={user}
                  setUser={setUser}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  saveArrayIndex={saveArrayIndex}
                />
              </Route>
              <Route exact path="/following-list/:id">
                <FollowingList user={user} />
              </Route>
              <Route exact path="/followers-list/:id">
                <Followerslist user={user} />
              </Route>
              {/* <Route exact path="/live">
                <Live
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  setLiveStreams={setLiveStreams}
                  liveStreams={liveStreams}
                  setVideos={setVideos}
                  videos={videos}
                  user={user}
                  setAllTrendingVideos={setAllTrendingVideos}
                  AllTrendingVideos={AllTrendingVideos}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                />
              </Route>
              <Route exact path="/livestreaming/:id">
                <LiveStreaming
                  user={user}
                  videos={videos}
                  setVideos={setVideos}
                />
              </Route> */}
              <Route exact path="/recording">
                <VoiceRecording
                  user={user}
                  AllTrendingVideos={AllTrendingVideos}
                  setAllTrendingVideos={setAllTrendingVideos}
                />
              </Route>
              <Route exact path="/recording/podcast">
                <VoiceRecordingPodcast
                  user={user}
                  setPodcastAudios={setPodcastAudios}
                  podcastAudios={podcastAudios}
                />
              </Route>
              <Route exact path="/trending">
                <Trending
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  setAllTrendingVideos={setAllTrendingVideos}
                  AllTrendingVideos={AllTrendingVideos}
                  user={user}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                />
              </Route>
              <Route exact path="/cast">
                <Cast
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  setAllTrendingVideos={setAllTrendingVideos}
                  AllTrendingVideos={AllTrendingVideos}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                  user={user}
                  podcastAudios={podcastAudios}
                  setPodcastAudios={setPodcastAudios}
                  openCastCommentDialog={openCastCommentDialog}
                  closeCastCommentDialog={closeCastCommentDialog}
                  openCastShare={openCastShare}
                />
              </Route>
              <Route exact path="/setting">
                <Setting user={user} setUser={setUser} />
              </Route>
              <Route exact path="/upload">
                <Upload user={user} />
              </Route>{" "}
              <Route path="/upload/podcast">
                <UploadPodcast user={user} />
              </Route>
              <Route path="/search">
                <Search
                  openCommentDialog={openCommentDialog}
                  openShare={openShare}
                  searchArray={searchArray}
                  searchEmailArray={searchEmailArray}
                  setAllTrendingVideos={setAllTrendingVideos}
                  AllTrendingVideos={AllTrendingVideos}
                  user={user}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                />
              </Route>
              {user && user.role && user.role === "admin" ? (
                <Route exact path="/admin">
                  <AdminPanel
                    user={user}
                    AllTrendingVideos={AllTrendingVideos}
                  />
                </Route>
              ) : (
                ""
              )}
              {user && user.role && user.role === "admin" ? (
                <Route exact path="/admin/addcategory">
                  <AddCategories />
                </Route>
              ) : (
                ""
              )}
              {user && user.role && user.role === "admin" ? (
                <Route exact path="/admin/editcategory">
                  <EditCategories />
                </Route>
              ) : (
                ""
              )}
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;

//"proxy": https://tokk2.com
//"proxy": "http://127.0.0.1:5000", // https://quiet-chamber-51842.herokuapp.com
