import React from "react";
import Trending from "../Trending/Trending";
import CastVideos from "./CastVideos";
const Cast = (props) => {
  const {
    openCommentDialog,
    openShare,
    setAllTrendingVideos,
    AllTrendingVideos,
    setIndexFunction,
    setSavedFunction,
    setUser,
    user,
    podcastAudios,
    setPodcastAudios,
    openCastCommentDialog,
    openCastShare,
  } = props;

  return (
    <div className="space-y-3">
      <div>
        <h1 className="text-hardGray text-2xl font-medium">Cast</h1>
        {/* <h4 className="text-hardGray mt-1">
          This video will be published to @user
        </h4> */}
        <CastVideos
          podcastAudios={podcastAudios}
          openCommentDialog={openCommentDialog}
          openCastShare={openCastShare}
          setIndexFunction={setIndexFunction}
          setSavedFunction={setSavedFunction}
          setUser={setUser}
          user={user}
          setPodcastAudios={setPodcastAudios}
          openCastCommentDialog={openCastCommentDialog}
        />
      </div>
      <Trending
        openCommentDialog={openCommentDialog}
        setIndexFunction={setIndexFunction}
        setSavedFunction={setSavedFunction}
        openShare={openShare}
        setAllTrendingVideos={setAllTrendingVideos}
        AllTrendingVideos={AllTrendingVideos}
        setUser={setUser}
      />
    </div>
  );
};

export default Cast;
