import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
const AdminPanel = (props) => {
  const deleteAudio = (e) => {
    axios({
      method: "DELETE",
      url: "/deleteaudio/" + e,
    }).then((res) => alert(res.data));
  };
  const { user, AllTrendingVideos } = props;
  return (
    <>
      <div className="bg-navbar rounded-md p-5 mb-5">
        <section className="flex space-x-5 items-center">
          <Avatar src={user ? user.image : ""}></Avatar>
          <div>
            <h1 className="text-4xl text-lightGray font-medium">Admin</h1>
            <h4 className="text-lightGray ">
              {user && user.username ? user.username : ""}
            </h4>
          </div>
        </section>
      </div>
      <div className="flex flex-wrap">
        {AllTrendingVideos
          ? AllTrendingVideos.map((data, i) => {
              return (
                <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1 ">
                  <div className="bg-navbar space-y-5 p-2">
                    <div style={{ position: "relative", paddingTop: "56.25%" }}>
                      <AudioPlayer
                        id="audioplayer"
                        preload="metadata"
                        crossOrigin
                        mediaGroup
                        src={`/getsingleaudio/${
                          data.audiouploads[0].audio.filename
                            ? data.audiouploads[0].audio.filename
                            : ""
                        }`}
                        onPlay={(e) => console.log("onPlay")}
                        width="100%"
                        height="100%"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                      />
                      <div className="flex justify-between">
                        <div className="flex space-x-3">
                          <Avatar src={data.image}>p</Avatar>
                          <div>
                            <h4 className="text-lightGray font-medium">
                              {data.audiouploads[0].title}
                            </h4>
                            <p className="text-sm text-lightGray">
                              {data.username}
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={(e) =>
                            deleteAudio(data.audiouploads[0].audio.filename)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};

export default AdminPanel;
