import React from "react";
import Videos from "./Videos";

const TrendingVideos = (props) => {
  const {
    openCommentDialog,
    openShare,
    moreTrending,
    user,
    AllTrendingVideos,
    setIndexFunction,
    setSavedFunction,
    setUser,
  } = props;
  //AllTrendingVideos.slice(0, moreTrending).map

  return (
    <div className="flex flex-wrap">
      {AllTrendingVideos &&
      AllTrendingVideos.length > 0 &&
      AllTrendingVideos[0].audiouploads &&
      AllTrendingVideos[0].audiouploads.length > 0 ? (
        AllTrendingVideos.map((audio, i) => {
          return (
            <>
              <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1">
                <Videos
                  key={i}
                  index={i}
                  title={audio.audiouploads[0].title}
                  message={audio.audiouploads[0].message}
                  audioUrl={audio.audiouploads[0].audio.filename}
                  tags={audio.audiouploads[0].tags}
                  comments={audio.audiouploads[0].comments}
                  user={user}
                  likeStatus={
                    user &&
                    user.AllLikes.find(
                      (data) =>
                        data.filename === audio.audiouploads[0].audio.filename
                    )
                  }
                  saveStatus={
                    user &&
                    user.savedaudios.find(
                      (data) =>
                        data.filename === audio.audiouploads[0].audio.filename
                    )
                  }
                  category={audio.audiouploads[0].category}
                  image={audio.image}
                  likes={audio.audiouploads[0].likes}
                  shares={audio.audiouploads[0].shares}
                  openCommentDialog={openCommentDialog}
                  videoThumbnail={audio.videoThumbnail}
                  openShare={openShare}
                  setIndexFunction={setIndexFunction}
                  setSavedFunction={setSavedFunction}
                  setUser={setUser}
                />
              </div>
            </>
          );
        })
      ) : (
        <span>No Audio Found</span>
      )}
    </div>
  );
};

export default TrendingVideos;
