import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import "../FollowingList/followinglist.css";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const FollowersList = (props) => {
  const [Followers, setFollowers] = useState(false);
  useEffect(() => {
    axios({
      method: "POST",
      url: "/getuserprofile/" + props.match.params.id,
    }).then((response) => {
      setFollowers(response.data.followers);
    });
  }, []);
  return (
    <div>
      <h1 className="followinglist">Followers list</h1>
      {Followers && Followers.length > 0 ? (
        <>
          <div className="followinglistprofiles">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>BUSINESS NAME</StyledTableCell>

                    <StyledTableCell align="right">ACTION</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {/* <ul className="profiles"> */}
                  {Followers && Followers.length > 0
                    ? Followers.map((data, i) => {
                        return (
                          <StyledTableRow key={i}>
                            <div className="searchprofilesfollowers">
                              <StyledTableCell
                                className="centertext"
                                component="th"
                                scope="row"
                              >
                                <img src={data.followerimage} alt=""></img>
                                <span className="followername">
                                  {data.followername}
                                </span>
                              </StyledTableCell>

                              {/* <StyledTableCell></StyledTableCell> */}
                            </div>
                            <StyledTableCell align="right">
                              <Link
                                className="viewbtn"
                                to={`/profile/${data.followerid}`}
                              >
                                View
                              </Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : ""}
                  {/* </ul> */}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <span className="nofollower">No Followers Found</span>
      )}
    </div>
  );
};

export default withRouter(FollowersList);
