import React, { useState, useEffect } from "react";
import Videos from "../Trending/Videos";
// import video from "../../assets/video/All over in 10 seconds.mp4";
// import salman from "../../assets/images/image.png";
import axios from "axios";

const ProfileVideos = (props) => {
  const {
    openCommentDialog,
    openShare,
    audiouploads,
    ProfileData,
    setIndexFunction,
    setSavedFunction,
    setUser,
    user,
  } = props;

  // useEffect(() => {
  //   axios({
  //     method: "POST",
  //     url: "/getaudio/" + ProfileData._id,
  //     // data: { _id: ProfileData._id },
  //   })
  //     .then((response) => {
  //       console.log("response is", response);
  //       setProfileVideo(response.data[0]);
  //     })
  //     .catch((e) => alert(e));
  // }, []);

  return (
    <div className="flex flex-wrap">
      {audiouploads
        ? audiouploads.map((video, i) => (
            <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1">
              <Videos
                title={video.title}
                index={i}
                user={user}
                message={video.message}
                openCommentDialog={openCommentDialog}
                comments={video.comments}
                audioUrl={video.audio.filename}
                likeStatus={
                  user &&
                  user.AllLikes.find(
                    (data) => data.filename === video.audio.filename
                  )
                }
                likes={video.likes}
                shares={video.shares}
                tags={video.tags}
                category={video.category}
                image={ProfileData.image}
                openShare={openShare}
                setIndexFunction={setIndexFunction}
                setSavedFunction={setSavedFunction}
                setUser={setUser}
              />
            </div>
          ))
        : ""}
    </div>
  );
};

export default ProfileVideos;

//  <Videos
//     openCommentDialog={openCommentDialog}
//     videoUrl={video.videoUrl}
//     videoThumbnail={video.videoThumbnail}
//     openShare={openShare}
//   />
//{videos.map((video) => (
//   <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1">
//      {this.state.new_product && this.state.new_product.length ? (
//         <>
//           {profileVideo.map((data, i) => {
//             const base64String = btoa(
//               String.fromCharCode(
//                 ...new Uint8Array(data.productimage.data.data)
//               )
//             );

//             return (
//               <Videos
//               openCommentDialog={openCommentDialog}
//               videoUrl={video.videoUrl}
//               videoThumbnail={video.videoThumbnail}
//               openShare={openShare}
//             />
//             );
//           })}
//           </>
//      ):""}

//   </div>

// ))}
