import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EditCategories = (props) => {
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios({
      method: "GET",
      url: "/getcategories",
    }).then((response) => {
      setAllCategories(response.data[0].category.sort());
    });
  };

  const deleteCategory = (category) => {
    console.log(category);
    for (let i = 0; i < allCategories.length; i++) {
      if (allCategories[i] === category) {
        allCategories.splice(i, 1);
      }
    }
    console.log(allCategories);
    axios({
      method: "POST",
      url: "/deletecategory",
      data: { allCategories },
    }).then((response) => {
      setAllCategories(response.data.category.sort());
    });
  };

  return (
    <>
      <h3 className="h3category">Edit Categories</h3>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>CATEGORY</StyledTableCell>

              <StyledTableCell align="right">ACTION</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allCategories && allCategories.length > 0
              ? allCategories.map((data, i) => {
                  console.log(data);
                  return (
                    <StyledTableRow key={i}>
                      <div className="searchprofilesfollowers">
                        <StyledTableCell
                          className="centertext"
                          component="th"
                          scope="row"
                        >
                          <span className="followername">{data}</span>
                        </StyledTableCell>

                        {/* <StyledTableCell></StyledTableCell> */}
                      </div>
                      <StyledTableCell align="right">
                        <Link
                          className="viewbtn"
                          onClick={() => deleteCategory(data)}
                        >
                          Delete
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : ""}
            {/* </ul> */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EditCategories;
