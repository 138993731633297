import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import "react-h5-audio-player/lib/styles.css";
// import AudioPlayer from "react-h5-audio-player";
import RecordApp from "./Record";
import CollectionsIcon from "@material-ui/icons/Collections";
import axios from "axios";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));
const VoiceRecording = (props) => {
  const { user, AllTrendingVideos, setAllTrendingVideos } = props;
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [media, setMedia] = useState();
  const [BlobURL, setBlobURL] = useState();
  const [disabledbtn, setDisabledbtn] = useState(true);
  const [SaveAudioBlob, setSaveAudioBlob] = useState();
  const [buffer, setBuffer] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [recording, setRecording] = useState({
    title: "",
    category: "",
    message: "",
    // tags: [],
  });
  const { title, category, message } = recording;

  const handleSubmit = (e) => {
    const { name, value } = e.target;
    setRecording({
      ...recording,
      [name]: value,
    });
  };
  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);

    setTags(newTags);
  };

  const inputKeyDown = (e) => {
    let val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }

      setTags([...tags, val]);
      e.target.value = "";
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };
  const uploadPostToTrending = async (e) => {
    e.preventDefault();

    var file = new File([buffer], "recording.mp3", {
      // type: recorderBlob.type,
      type: buffer[0].type,
      lastModified: Date.now(),
    });

    alert("post successfully");

    const data = new FormData();
    // data.append("file", file);
    data.append("file", SaveAudioBlob);

    data.append("tags", tags);
    data.append("title", title);
    data.append("category", category);
    data.append("message", message);
    data.append("_id", user._id);
    axios({
      method: "POST",
      url: "/uploadaudio",
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          alert("File uploaded successfully");
        }
      })
      .catch((e) => alert(e));
  };

  const reader = new FileReader();
  const uploadMedia = (e) => {
    let files = e.target.files;
    if (files[0]) {
      reader.readAsDataURL(files[0]);
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file: ${files.name}`);
      });
      reader.onload = (e) => {
        let file = e.target.result;

        // set profile to send in server base64
        setMedia(file);
        setMedia(files[0]);
      };
    }
  };

  const inviteRecord = () => {
    alert("invite for record working...");
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios({
      method: "GET",
      url: "/getcategories",
    }).then((response) => {
      setAllCategories(response.data[0].category.sort());
    });
  };

  return (
    <div>
      <section className="flex space-x-5 items-center">
        <Avatar src={user ? user.image : ""} className={classes.large}></Avatar>
        <div className="w-full">
          <h1 className="text-3xl text-lightGray font-medium">
            {user ? user.username : ""}
          </h1>
          <div className="w-full recordingdiv">
            <button className="bg-post px-7 text-navbar rounded-full">
              <Link to="/recording/podcast">PodCast Recording</Link>
            </button>
          </div>
        </div>
      </section>
      {/* recording card */}

      <div className="bg-navbar rounded-md p-5 mt-5">
        <section>
          <section className="space-y-5">
            <input
              type="text"
              name="title"
              value={title}
              placeholder="Title"
              className="w-full outline-none bg-sidebar p-2"
              required
              onChange={handleSubmit}
            />
            <select
              className=" outline-none bg-sidebar p-2 w-full"
              value={category}
              onChange={handleSubmit}
              name="category"
            >
              {/* <option value="comedy">Comedy</option>
              <option value="action">Action</option>
              <option value="sad">Sad</option>
              <option value="traveling">Traveling</option> */}
              <option>Category</option>
              {allCategories && allCategories.length > 0
                ? allCategories.map((data, i) => {
                    return (
                      <option key={i} value={data.toLowerCase()}>
                        {data}
                      </option>
                    );
                  })
                : ""}
            </select>
            <textarea
              name="message"
              value={message}
              className="outline-none bg-sidebar w-full p-2"
              placeholder="Type Message"
              rows="2"
              required
              onChange={handleSubmit}
            ></textarea>
            <RecordApp
              setBlobURL={setBlobURL}
              BlobURL={BlobURL}
              setBuffer={setBuffer}
              setDisabledbtn={setDisabledbtn}
              setSaveAudioBlob={setSaveAudioBlob}
              disabledbtn={disabledbtn}
            />
            <div className="flex items-center space-x-2">
              <label htmlFor="uploadMedia">
                <CollectionsIcon className="text-lightGray cursor-pointer" />
              </label>
              <input
                type="file"
                id="uploadMedia"
                hidden
                onChange={uploadMedia}
              />
              <div className="input-tag">
                <ul className="input-tag__tags">
                  {tags.map((tag, i) => (
                    <li key={tag}>
                      {tag}
                      <button
                        type="button"
                        onClick={() => {
                          removeTag(i);
                        }}
                      >
                        +
                      </button>
                    </li>
                  ))}

                  <li className="input-tag__tags__input">
                    <input type="text" onKeyDown={inputKeyDown} />
                  </li>
                </ul>
              </div>

              <button
                className="bg-post px-7 text-navbar rounded-full"
                type="submit"
                onClick={uploadPostToTrending}
                disabled={disabledbtn === true}
              >
                Post To Trending Audios
              </button>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default VoiceRecording;
