import React, { useEffect } from "react";
import { useState } from "react";
import { ImCloudUpload } from "react-icons/im";
import "../../../App.css";
import axios from "axios";
import { Link } from "react-router-dom";
const UploadPodcast = ({ user }) => {
  const [post, setPost] = useState({
    title: "",
    category: "",

    message: "",
  });

  const { title, category, message } = post;

  const handleSubmit = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };
  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);

    setTags(newTags);
  };

  const inputKeyDown = (e) => {
    let val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }

      setTags([...tags, val]);
      e.target.value = "";
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  const [tags, setTags] = useState([]);
  const [audio, setAudio] = useState();
  const [allCategories, setAllCategories] = useState([]);

  const reader = new FileReader();
  const audioUpload = (e) => {
    let files = e.target.files;

    if (files[0]) {
      reader.readAsDataURL(files[0]);
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file: ${files.name}`);
      });
      reader.onload = (e) => {
        let file = e.target.result;

        setAudio(file);
        setAudio(files[0]);
      };
    }
  };
  const uploadPostToPodcast = async (e) => {
    e.preventDefault();

    alert("post successfully");

    const data = new FormData();

    data.append("file", audio);
    data.append("name", user.username);
    data.append("tags", tags);
    data.append("title", title);
    data.append("category", category);

    data.append("_id", user._id);
    axios({
      method: "POST",
      url: "/uploadtopodcast",
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          alert("File uploaded successfully");
        }
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios({
      method: "GET",
      url: "/getcategories",
    }).then((response) => {
      setAllCategories(response.data[0].category.sort());
    });
  };

  return (
    <div>
      <h1 className="text-hardGray text-2xl font-medium">Upload</h1>
      {/* <h4 className="text-hardGray mt-1">
        This video will be published to @user
      </h4> */}
      {/* upload card */}
      <div className="w-full recordingdiv">
        <button className="bg-post px-7 text-navbar rounded-full">
          <Link to="/upload">Upload</Link>
        </button>
      </div>
      <div className="flex bg-navbar p-5 space-x-7 mt-7">
        <section className="bg-sidebar rounded-md w-96 flex flex-col items-center justify-center p-5">
          <label htmlFor="uploadAudio">
            <ImCloudUpload
              size={74}
              className="text-lightGray cursor-pointer"
            />
          </label>
          <input type="file" hidden id="uploadAudio" onChange={audioUpload} />
          <h1 className="text-sidebar">
            {audio ? audio?.name : "Select to Upload Recorded Audio"}
          </h1>
        </section>
        <section>
          <div className="space-y-5">
            <input
              type="text"
              name="title"
              value={title}
              placeholder="Title"
              className="w-full outline-none bg-sidebar p-2"
              required
              onChange={handleSubmit}
            />
            <select
              className=" outline-none bg-sidebar p-2 w-full"
              value={category}
              onChange={handleSubmit}
              name="category"
            >
              {/* <option value="comedy">Comedy</option>
              <option value="action">Action</option>
              <option value="sad">Sad</option>
              <option value="traveling">Traveling</option> */}
              <option>Category</option>
              {allCategories && allCategories.length > 0
                ? allCategories.map((data, i) => {
                    return (
                      <option key={i} value={data.toLowerCase()}>
                        {data}
                      </option>
                    );
                  })
                : ""}
            </select>
            {/* <input
              name="tags"
              value={tags}
              type="text"
              placeholder="#tags"
              className="w-full outline-none bg-sidebar p-2"
              required
              onChange={handleSubmit}
            /> */}
            <div className="input-tag">
              <ul className="input-tag__tags">
                {tags.map((tag, i) => (
                  <li key={tag}>
                    {tag}
                    <button
                      type="button"
                      onClick={() => {
                        removeTag(i);
                      }}
                    >
                      +
                    </button>
                  </li>
                ))}

                <li className="input-tag__tags__input">
                  <input type="text" onKeyDown={inputKeyDown} />
                </li>
              </ul>
            </div>

            <textarea
              name="message"
              value={message}
              className="outline-none bg-sidebar w-full p-2"
              placeholder="Type Message"
              rows="5"
              required
              onChange={handleSubmit}
            ></textarea>
            <div className="flex justify-end">
              <button
                className="bg-post px-7 text-navbar rounded-full"
                type="submit"
                onClick={uploadPostToPodcast}
              >
                Post To Podcast
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UploadPodcast;
