import React, { useState, useEffect } from "react";
import Videos from "../Trending/Videos";
import axios from "axios";
import { withRouter } from "react-router";
const SavedVideos = (props) => {
  const {
    openCommentDialog,
    openShare,
    setIndexFunction,

    user,
    setSavedFunction,
    saveArrayIndex,
    setUser,
  } = props;

  const [saveArray, setSaveArray] = useState([]);

  useEffect(() => {
    axios({
      method: "POST",
      url: "/getsavedvideos/" + props.match.params.id,
    }).then((response) => {
      setSaveArray(response.data);
    });
  }, []);
  return (
    <div className="flex flex-wrap">
      {saveArray && saveArray.length > 0
        ? saveArray.map((data, i) => (
            <div className="w-full sm:w-2/4 md:w-2/6 xl:w-1/4 p-1">
              <Videos
                openCommentDialog={openCommentDialog}
                user={user}
                index={i}
                setIndexFunction={setIndexFunction}
                setSavedFunction={setSavedFunction}
                openShare={openShare}
                audioUrl={data.audiouploads[0].audio.filename}
                image={data.image}
                comments={data.audiouploads[0].comments}
                likes={data.audiouploads[0].likes}
                message={data.audiouploads[0].message}
                shares={data.audiouploads[0].shares}
                title={data.audiouploads[0].title}
                tags={data.audiouploads[0].tags}
                category={data.audiouploads[0].category}
                setUser={setUser}
              />
            </div>
          ))
        : ""}
    </div>
  );
};

export default withRouter(SavedVideos);
