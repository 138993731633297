import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import instagram from "../../assets/images/instagram.png";
import { withRouter } from "react-router-dom";

import { FacebookShareButton } from "react-share";
import axios from "axios";
const SharePodcast = (props) => {
  const [ShareArray, setShareArray] = useState([]);

  const { closeCastShare, CastOpen, IndexArray } = props;

  useEffect(() => {
    axios({
      method: "POST",
      url: "/getpodcastcomments",
      data: { IndexArray },
    }).then((response) => {
      setShareArray(response.data);
    });
  }, [IndexArray]);

  const countShares = () => {
    let oldShares =
      ShareArray && ShareArray.length > 0 && ShareArray[0].podcastuploads
        ? ShareArray[0].podcastuploads.shares
        : "";

    oldShares = oldShares + 1;
    axios({
      method: "POST",
      url: "/addsharetopodcast",
      data: {
        share: oldShares,
        audioUrl: IndexArray,
      },
    }).then((response) => {
      console.log(response.data);
      //   setShareArray({ audiouploads: { shares: oldShares } });
    });
  };

  return (
    <div>
      <Dialog open={CastOpen} onClose={closeCastShare}>
        <div className="px-5">
          <div className="flex items-center justify-between">
            <DialogTitle>Share</DialogTitle>
            <CloseIcon onClick={closeCastShare} className="cursor-pointer" />
          </div>
          <DialogContent dividers>
            <div className="flex space-x-10 items-center p-4">
              <FacebookShareButton
                url={`https://www.facebook.com/sharer/sharer.php?u=https://tokk2.com/getsingleaudio/${IndexArray}`}
                quote={"Audio"}
                // hashtag={shareHashTag}
              >
                {" "}
                <FaFacebook
                  size={38}
                  onClick={countShares}
                  className="text-facebook cursor-pointer"
                />
              </FacebookShareButton>
              {/* </FaFacebook> */}
              <img
                src={instagram}
                alt="instagram"
                height="37px"
                onClick={countShares}
                width="37px"
                className="cursor-pointer"
              />

              <a
                href={`https://api.whatsapp.com/send?phone=&text=https://tokk2.com/getsingleaudio/${IndexArray}`}
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoWhatsapp
                  size={38}
                  className="text-whatsApp cursor-pointer"
                  onClick={countShares}
                ></IoLogoWhatsapp>
              </a>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(SharePodcast);
