import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import ReactPlayer from "react-player";

import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
// import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";
import PodcastComment from "../Comments/commentsPodcast";
import SharePodcast from "../Comments/sharePodcast";

const AllCastVideos = (props) => {
  // const [oneAudio, setOneAudio] = useState("");
  let {
    title,
    tags,
    likes,
    index,
    image,
    audioUrl,
    user,
    likeStatus,
    comments,

    openCastShare,
    setIndexFunction,
    shares,
    category,
    openCommentDialog,

    name,
    setPodcastAudios,
    openCastCommentDialog,
  } = props;

  const [newCastLikes, setCastNewLikes] = useState(likes);
  const [newCastLikeStatus, setNewCastLikeStatus] = useState(likeStatus);
  let newLike = 0;
  const passdata = (e) => {
    openCastCommentDialog();
    setIndexFunction(audioUrl);
  };
  const passdata2 = () => {
    openCastShare();
    setIndexFunction(audioUrl);
  };

  const toggleLike = (e) => {
    if (newCastLikeStatus === undefined) {
      newLike = newCastLikes + 1;
      setCastNewLikes(newLike);
      likeStatus = audioUrl;
      setNewCastLikeStatus(likeStatus);
      axios({
        method: "POST",
        url: "/increasecastlikes",
        data: { newLike, audioUrl, _id: user._id },
      }).then((response) => {
        console.log(response);
      });
    } else {
      newLike = newCastLikes - 1;
      setCastNewLikes(newLike);

      axios({
        method: "POST",
        url: "/decreasecastlike",
        data: { newLike, audioUrl, _id: user._id },
      }).then((response) => {
        likeStatus = undefined;
        setNewCastLikeStatus(likeStatus);
      });
    }
  };

  useEffect(() => {
    setNewCastLikeStatus(likeStatus);
    setCastNewLikes(likes);
  }, [likeStatus, likes]);

  return (
    <div className="bg-navbar rounded-md p-2">
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        {audioUrl && audioUrl !== "" ? (
          <AudioPlayer
            id="audioplayer"
            preload="metadata"
            crossOrigin
            mediaGroup
            src={`/getsingleaudio/${audioUrl}`}
            onPlay={(e) => console.log("onPlay")}
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div className="space-y-3">
        <div className="flex space-x-3">
          <Avatar src={image}>p</Avatar>
          <div>
            <h4 className="text-lightGray font-medium">{title}</h4>
            <p className="text-sm text-lightGray">{name}</p>
          </div>
        </div>
        <div className="flex space-x-5">
          <section
            className="flex flex-col items-center text-heart"
            onClick={toggleLike}
          >
            <AiFillHeart
              style={{ height: "40px", width: "40px" }}
              className={`likeHeart cursor-pointer ${
                newCastLikeStatus === undefined ? "" : "text-danger"
              }`}
            />

            <h4 className="text-xs font-bold">{newCastLikes}</h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <FaCommentDots
              style={{ height: "40px", width: "40px" }}
              className="cursor-pointer"
              // onClick={openCommentDialog}
              onClick={passdata}
            />

            <PodcastComment
              index={index}
              title={title}
              name={name}
              likes={likes}
              image={image}
              audioUrl={audioUrl}
              user={user}
              likeStatus={likeStatus}
            />
            <h4 className="text-xs font-bold">
              {comments ? comments.length : ""}
            </h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <IoIosShareAlt
              style={{ height: "40px", width: "40px" }}
              className="cursor-pointer"
              onClick={passdata2}
              // onClick={openShare}
            />
            <SharePodcast
              index={index}
              title={title}
              name={name}
              likes={likes}
              image={image}
              audioUrl={audioUrl}
              user={user}
              likeStatus={likeStatus}
            />
            <h4 className="text-xs font-bold">{shares ? shares : 0}</h4>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AllCastVideos;
