import { Avatar } from "@material-ui/core";
import React, { useState } from "react";
import ReactPlayer from "react-player";
// import team from "../../assets/images/team.jpg";
import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
import { withRouter } from "react-router";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import { Link } from "react-router-dom";
const LiveStreamingVideos = (props) => {
  let {
    title,
    message,
    tags,
    category,
    image,
    audioUrl,
    videoThumbnail,
    openCommentDialog,
    openShare,
  } = props;

  return (
    <div className="bg-navbar rounded-md p-2">
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <Link to={`/livestreaming/${audioUrl}`}>
          <ReactPlayer
            src=""
            light={videoThumbnail}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
            style={{ position: "absolute", top: "0", left: "0" }}
          />
        </Link>
      </div>
      <div className="space-y-3">
        <div className="flex space-x-3">
          <Avatar src={image}>p</Avatar>
          <div>
            <h4 className="text-lightGray font-medium">{title}</h4>
            <p className="text-sm text-lightGray">{message}</p>
          </div>
        </div>
        {/* <div className="flex space-x-5">
          <section className="flex flex-col items-center text-heart">
            <AiFillHeart className="text-danger cursor-pointer" />
            <h4 className="text-xs font-bold">10k</h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <FaCommentDots
              className="cursor-pointer"
              onClick={openCommentDialog}
            />
            <h4 className="text-xs font-bold">150</h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <IoIosShareAlt className="cursor-pointer" onClick={openShare} />
            <h4 className="text-xs font-bold">150k</h4>
          </section>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(LiveStreamingVideos);
