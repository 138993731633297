import React, { useEffect, useState } from "react";
import axios from "axios";

const AddCategories = (props) => {
  const [category, setCategory] = useState("");
  const submit = () => {
    axios({
      method: "POST",
      url: "/addcategories",
      data: { category },
    }).then((response) => {
      alert(response.data);
      setCategory("");
    });
  };
  return (
    <>
      <h3 className="h3category">Add New Category</h3>
      <div className="addcategories">
        <input
          type="text"
          name="category"
          id="category"
          value={category}
          placeholder="     Category Name"
          onChange={(e) => setCategory(e.target.value)}
        />
        <br />
        <button
          disabled={category === ""}
          className="bg-post px-7 text-navbar rounded-full"
          onClick={submit}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default AddCategories;
