import React, { useState, useEffect } from "react";
import Keywords from "./Keywords";
import TrendingVideos from "./TrendingVideos";

const Trending = (props) => {
  const {
    openCommentDialog,
    openShare,
    AllTrendingVideos,
    setAllTrendingVideos,
    user,
    setIndexFunction,
    setSavedFunction,
    setUser,
  } = props;

  return (
    <div>
      <h1 className="text-hardGray text-2xl font-medium">Top Trendings</h1>

      <Keywords setAllTrendingVideos={setAllTrendingVideos} />

      <TrendingVideos
        setAllTrendingVideos={setAllTrendingVideos}
        user={user}
        AllTrendingVideos={AllTrendingVideos}
        openCommentDialog={openCommentDialog}
        openShare={openShare}
        setIndexFunction={setIndexFunction}
        setSavedFunction={setSavedFunction}
        setUser={setUser}
      />
    </div>
  );
};

export default Trending;
