import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import ReactPlayer from "react-player";

import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
// import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";
import Comment from "../Comments/Comment";
import Share from "../Comments/Share";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
const Videos = (props) => {
  // const [oneAudio, setOneAudio] = useState("");
  let {
    title,
    message,
    tags,
    likes,
    index,
    image,
    audioUrl,
    user,
    likeStatus,
    comments,
    openCommentDialog,
    openShare,
    setIndexFunction,
    shares,
    setUser,
    setSavedFunction,
    saveStatus,
  } = props;

  let newLike = 0;
  const [newLikes, setNewLikes] = useState(likes);
  const [newLikeStatus, setNewLikeStatus] = useState(likeStatus);
  const [newSaveStatus, setNewSaveStatus] = useState(saveStatus);

  const passdata = (e) => {
    openCommentDialog();
    setIndexFunction(audioUrl);
  };
  const passdata2 = () => {
    openShare();
    setIndexFunction(audioUrl);
  };
  const saveVideos = (e) => {
    if (newSaveStatus === undefined) {
      setSavedFunction(audioUrl);
      axios({
        method: "POST",
        url: "/savedvideos",
        data: { saveArrayIndex: audioUrl, _id: user._id },
      }).then((response) => {
        saveStatus = audioUrl;
        setNewSaveStatus(saveStatus);

        setUser({
          savedaudios: response.data.savedaudios,
          AllLikes: response.data.AllLikes,
          audiouploads: response.data.audiouploads,
          email: response.data.email,
          followers: response.data.followers,
          following: response.data.following,
          image: response.data.image,

          username: response.data.username,

          _id: response.data._id,
        });
      });
    }
  };
  const removeSave = () => {
    if (newSaveStatus !== "undefined") {
      setSavedFunction(audioUrl);
      axios({
        method: "POST",
        url: "/removevideos",
        data: { saveArrayIndex: audioUrl, _id: user._id },
      }).then((response) => {
        saveStatus = undefined;
        setNewSaveStatus(saveStatus);
        setUser({
          savedaudios: response.data.savedaudios,
          AllLikes: response.data.AllLikes,
          audiouploads: response.data.audiouploads,
          email: response.data.email,
          followers: response.data.followers,
          following: response.data.following,
          image: response.data.image,

          username: response.data.username,

          _id: response.data._id,
        });
      });
    }
  };

  const toggleLike = (e) => {
    if (newLikeStatus === undefined) {
      // likes = likes + 1;
      // setNewLikes(likes);
      // console.log(likes);
      newLike = newLikes + 1;
      setNewLikes(newLike);
      axios({
        method: "POST",
        url: "/increaselikes",
        data: { newLike, audioUrl, _id: user._id },
      }).then((response) => {
        likeStatus = audioUrl;
        setNewLikeStatus(likeStatus);
      });
    } else {
      // likes = likes - 1;
      // console.log(likes);
      // setNewLikes(likes);
      newLike = newLikes - 1;
      setNewLikes(newLike);

      axios({
        method: "POST",
        url: "/decreaselike",
        data: { newLike, audioUrl, _id: user._id },
      }).then((response) => {
        likeStatus = undefined;
        setNewLikeStatus(likeStatus);
      });
    }
  };
  useEffect(() => {
    setNewLikeStatus(likeStatus);
    setNewLikes(likes);
    setNewSaveStatus(saveStatus);
  }, [likeStatus, likes, saveStatus]);

  return (
    <div className="bg-navbar rounded-md p-2">
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        {audioUrl && audioUrl !== "" ? (
          <AudioPlayer
            id="audioplayer"
            preload="metadata"
            crossOrigin
            mediaGroup
            src={`/getsingleaudio/${audioUrl}`}
            onPlay={(e) => console.log("onPlay")}
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div className="space-y-3">
        <div className="flex space-x-3">
          <Avatar src={image}>p</Avatar>
          <div>
            <h4 className="text-lightGray font-medium">{title}</h4>
            <p className="text-sm text-lightGray">{message}</p>
          </div>
        </div>
        <div className="flex space-x-5">
          <section
            className="flex flex-col items-center text-heart"
            onClick={toggleLike}
          >
            <AiFillHeart
              style={{ height: "40px", width: "40px" }}
              className={`likeHeart cursor-pointer ${
                newLikeStatus && newLikeStatus !== undefined
                  ? "text-danger"
                  : ""
              }`}
            />

            <h4 className="text-xs font-bold">{newLikes}</h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <FaCommentDots
              style={{ height: "40px", width: "40px" }}
              className="cursor-pointer"
              // onClick={openCommentDialog}
              onClick={passdata}
            />

            <Comment
              index={index}
              title={title}
              message={message}
              likes={likes}
              image={image}
              audioUrl={audioUrl}
              user={user}
              likeStatus={likeStatus}
            />
            <h4 className="text-xs font-bold">
              {comments ? comments.length : ""}
            </h4>
          </section>
          <section className="flex flex-col items-center text-heart">
            <IoIosShareAlt
              style={{ height: "40px", width: "40px" }}
              className="cursor-pointer"
              onClick={passdata2}
              // onClick={openShare}
            />
            <Share
              index={index}
              title={title}
              message={message}
              likes={likes}
              image={image}
              audioUrl={audioUrl}
              user={user}
              likeStatus={likeStatus}
            />
            <h4 className="text-xs font-bold">{shares ? shares : 0}</h4>
          </section>
          {newSaveStatus && newSaveStatus !== undefined ? (
            <BookmarksIcon
              style={{ height: "40px", width: "40px" }}
              className="cursor-pointer"
              onClick={removeSave}
            />
          ) : (
            <BookmarkBorderIcon
              style={{ height: "40px", width: "40px" }}
              onClick={saveVideos}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
